<template>
    <div>
        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.COMPUTE_POWER') }}</h4>
        <b-row v-if="Object.values(options).length > 0">
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="configuration.cores"
                                    :step="options.cores.step"
                                    :min="options.cores.min_units"
                                    :max="options.cores.max_units"
                                    name="cores"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.CORES`, 2)"
                        />
                    </div>
                </div>
            </b-col>
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="configuration.memory"
                                    :step="options.memory.step"
                                    :min="options.memory.min_units"
                                    :max="options.memory.max_units"
                                    name="memory"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.MEMORY`, 2)"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-button v-if="configurationChanged" variant="primary" @click="changeConfiguration">
            Übernehmen
        </b-button>
        <hr>
        <h4>Festplatten</h4>
        <div class="row mb-10 position-relative">
            <div v-for="(storage, index) in configuration.storages"
                 :key="index"
                 class="col-md-4 mt-3"
            >
                <CloudServerDriveConfigurator :options="diskOptions" :deletable="configuration.storages.length > 1" :storage="storage" :index="index" @delete="deleteStorage(storage, index)" />
            </div>
            <div class="col-md-4 mt-3">
                <div v-if="configuration.storages.length < 5"
                     class="card card-custom card-stretch opacity-50 hover-opacity-80 cursor-pointer"
                     style="min-height: 130px"
                     @click="addStorage"
                >
                    <div class="text-center"
                         style="position: absolute;display: inline;left:50%;top: 50%;transform: translate(-50%,-50%); font-size: 50px;"
                    >
                        <b-icon-plus />
                    </div>
                </div>
            </div>
        </div>
        <b-button v-if="storagesChanged.length > 0" variant="primary" @click="confirmStorageChange">
            Änderungen anwenden
        </b-button>
        <hr>
        <h4>Netzwerk</h4>
        <b-alert variant="warning" :show="true">
            Dieser Bereich ist noch in Arbeit
        </b-alert>
        <h4>Neuinstallation</h4>
        <CloudServerTemplateSelect v-model="reinstallTemplate" class="mb-5" />
        <b-button variant="primary">
            Neuinstallation durchführen
        </b-button>
    </div>
</template>

<script>
import { CloudServer } from '@/core/services/store/models/cloud_server'
import projectMixin from '@/core/mixins/projectMixin'
import customerMixin from '@/core/mixins/customerMixin'
import SliderEdit from '@/view/components/helper/SliderEdit'
import CloudServerTemplateSelect from '@/view/components/cloudserver/CloudServerTemplateSelect'
import CloudServerPricing from '@/core/services/store/models/cloud_server_pricing'
import CloudServerDriveConfigurator from '@/view/components/cloudserver/configurators/CloudServerDriveConfigurator'

export default {
    name: 'CloudServerConfiguration',
    components: { CloudServerDriveConfigurator, CloudServerTemplateSelect, SliderEdit },
    mixins: [projectMixin, customerMixin],
    props: {
        server: {
            type: CloudServer,
            required: true
        }
    },
    data() {
        return {
            options: [],
            configuration: {
                cores: 1,
                memory: 1,
                storages: [],
                networks: []
            },
            reinstallTemplate: 'DEBIAN_10',
            diskOptions: {}
        }
    },
    computed: {
        /**
         * @returns {boolean} true if the configuration changed
         */
        configurationChanged() {
            if (!this.server) { return false }
            return this.server.cores !== this.configuration.cores || (this.server.memory / 1024) !== this.configuration.memory
        },
        /**
         * @returns {[]} changed storages
         */
        storagesChanged() {
            return this.configuration.storages.filter(s => {
                if (!s.id) return true
                const existingStorage = this.server.storages.find(s1 => s.id === s1.id)
                return existingStorage.size !== s.size
            })
        },
        /**
         * @returns {[]} changed networks
         */
        networksChanged() {
            return this.configuration.networks.filter(n => {
                if (!n.id) return true
                const existingNetwork = this.server.networks.find(n1 => n.id === n1.id)
                return existingNetwork.size !== n.size
            })
        }
    },
    methods: {
        async loadPricing () {
            const response = await this.api.getCloudServerPricing({ customer_id: this.customer.id, project_uuid: this.project.uuid })

            const memory = response.data.memory
            response.data.memory = {
                ...memory,
                min_units: memory.min_units / 1024,
                max_units: memory.max_units / 1024,
                step: memory.step / 1024
            }

            this.options = response.data
        },
        async changeConfiguration() {
            const answer = await this.$swal({ title: 'Neustart erforderlich', text: 'Sollen die Änderungen wirklich übernommen und der Server neugestartet werden?', showConfirmButton: true, showCancelButton: true, icon: 'warning' })
            if (answer.isConfirmed) {
                const response = await this.api.updateCloudServer(this.server.id, { ...this.configuration, memory: this.configuration.memory * 1024 })
                await CloudServer.insertOrUpdate(response)
                await this.$swal({ title: 'Erfolgreich', text: 'Die Konfiguration wurde geändert', icon: 'success' })
            }
        },
        addStorage() {
            this.configuration.storages.push({ size: 10, replication: 2 })
        },
        async deleteStorage(storage, index) {
            if (!storage.id) { // at this point the storage isn't commited to the backend yet
                this.configuration.storages.splice(index, 1)
                return
            }
            const answer = await this.$swal({ title: 'Festplatte löschen', text: 'Soll die Festplatte wirklich gelöscht werden?\n Dies wird alle Daten löschen!', showConfirmButton: true, showCancelButton: true, icon: 'warning' })
            if (answer.isConfirmed) {
                await this.api.deleteCloudServerStorage(this.server.id, storage.title)
                this.configuration.storages.splice(index, 1)
                await this.$swal({ title: 'Erfolgreich', text: 'Die Festplate wurde gelöscht!', icon: 'success' })
            }
        },
        async confirmStorageChange() {
            await this.$swal({
                title: 'Festplatten ändern',
                html: 'Dies wird folgende Festplatten ändern:<br>' + this.storagesChanged.map(s => `<b>${s.title || 'Neue Festplatte'}</b> ${s.size}gb (${this.calculateStorage(s)})`).join('<br>'),
                showConfirmButton: true,
                showCancelButton: true
            })
        },
        calculateStorage (storage) {
            let units = storage.size - this.diskOptions.free_units
            if (units < 0) {
                units = 0
            }

            const monthly = units / this.diskOptions.step * this.diskOptions.price
            const options = { style: 'currency', currency: 'EUR' }
            return Intl.NumberFormat('de-DE', options).format(monthly / 30) + ' Tgl. | ' + Intl.NumberFormat('de-DE', options).format(monthly) + ' Mtl.'
        }
    },
    watch: {
        server: {
            immediate: true,
            handler (newValue, oldValue) {
                this.configuration.cores = newValue.cores
                this.configuration.memory = newValue.memory / 1024
                this.configuration.storages = newValue.storages.map(s => ({ ...s })) // clone storages to mutate
            }
        },
        project: {
            immediate: true,
            async handler(newValue, oldValue) {
                if (newValue && this.customer) {
                    this.loadPricing()
                    await CloudServerPricing.fetch(this.customer.id, newValue.id)
                    this.diskOptions = await CloudServerPricing.getByUnit('disk')
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
