<template>
    <div>
        <b-modal id="VserverFirewallCreateModal"
                 title="Firewall Regel anlegen"
                 :hide-footer="true"
                 back
                 size="xl"
        >
            <CloudServerFirewallConfiguration
                v-model="rule"
                @submit="onCreate(rule)"
                @linkRuleset="onLink"
            />
        </b-modal>
    </div>
</template>

<script>
import CloudServerFirewallConfiguration from '@/view/components/cloudserver/CloudServerFirewallConfiguration'
import api from '@/core/services/openapi.service'
import { CloudServer } from '@/core/services/store/models/cloud_server'

export default {
    name: 'CloudServerFirewallCreateModal',
    components: { CloudServerFirewallConfiguration },

    props: {
        server: {
            type: CloudServer,
            required: true
        }
    },

    data () {
        return {
            ruleDefaults: {
                action: 'ACCEPT',
                title: '',
                order: 0,
                direction: 'IN',
                protocol: 'tcp',
                interface: 'net0',
                source_address: '',
                source_address_set_id: null,
                source_port: '',
                destination_address: '',
                destination_address_set_id: null,
                destination_port: ''
            },
            rule: {}
        }
    },

    methods: {
        async onCreate () {
            this.$wait.start('firewall-create')

            const body = this.rule
            if (body.source_address == null) { delete body.source_address }
            if (body.source_address_set_id == null) { delete body.source_address_set_id }
            if (body.destination_address == null) { delete body.destination_address }
            if (body.destination_address_set_id == null) { delete body.destination_address_set_id }

            const response = await api.createCloudServerFirewallRule(this.server.id, this.rule)

            this.$emit('onCreate', response.data.data)

            this.$bvModal.hide('VserverFirewallCreateModal')
            this.$wait.end('firewall-create')
            this.rule = { ...this.ruleDefaults }
        },

        async onLink (linkId) {
            this.$wait.start('firewall-ruleset-link')

            const response = await api.createCloudServerFirewallRule(this.server.id, {
                title: this.rule.title,
                linked_rule_set_id: linkId,
                order: 0
            })

            this.$emit('onCreate', response.data.data)

            this.$bvModal.hide('VserverFirewallCreateModal')
            this.$wait.end('firewall-ruleset-link')
            this.rule = { ...this.ruleDefaults }
        }
    },

    created () {
        this.rule = { ...this.ruleDefaults }
    }
}
</script>

<style scoped>

</style>
