<template>
    <div class="card card-custom card-stretch">
        <div class="card-body pb-3 pt-3">
            <SliderEdit
                v-model="storage.size"
                name="disk"
                :step="options.step"
                :min="options.min_units"
                :max="options.max_units"
                :title="$t('DATACENTER.VSERVERS.CONFIGURATION.DISKS.ONE', {n: (index+1)})"
            />

            <div class="mt-2 text-center font-italic">
                <b-button
                    v-if="deletable"
                    variant="link"
                    size="sm"
                    tabindex="-1"
                    style="position: absolute; right: -20px; top: -20px;"
                    class="text-decoration-none"
                    @click="$emit('delete', index)"
                >
                    <span style="width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background: rgba(0, 0, 0, .5); font-size: 1.5em;">
                        <b-icon-x style="fill: white;" />
                    </span>
                </b-button>

                <p class="text-center">
                    {{ storage.title }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SliderEdit from '@/view/components/helper/SliderEdit'

export default {
    name: 'CloudServerDriveConfigurator',
    components: { SliderEdit },
    props: {
        storage: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            default: () => ({
                step: 5,
                min_units: 5,
                max_units: 100
            })
        },
        index: {
            type: Number,
            default: 0
        },
        deletable: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>
