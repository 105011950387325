<template>
    <div style="width: 600px; height: 300px">
        <apexchart
            v-if="diskWrite.length>0 && diskRead.length>0"
            width="600"
            height="300"
            :options="options"
            :series="series"
            type="line"
        />
        <b-spinner v-else class="position-absolute m-auto top-0 bottom-0 left-0 right-0" variant="primary" />
    </div>
</template>

<script>
import defaultOptions from '@/core/config/apexcharts/defaultOptions'

export default {
    name: 'DiskGraph',
    props: {
        status: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            diskWrite: [],
            diskRead: [],
            options: {
                ...defaultOptions,
                labels: ['Festplatte'],
                yaxis: {
                    min: 0,
                    title: {
                        text: 'Auslastung'
                    }
                },
                xaxis: {
                    tooltip: {
                        enabled: false
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    }
                }
            }
        }
    },
    computed: {
        series: function () {
            return [
                {
                    name: 'Lesezugriff',
                    data: this.diskRead
                },
                {
                    name: 'Schreibzugriff',
                    data: this.diskWrite
                }
            ]
        }
    },
    watch: {
        status: function (status) {
            this.diskRead.push([Date.now(), (status.diskread / 1048576).toFixed(2)])
            this.diskWrite.push([Date.now(), (status.diskwrite / 1048576).toFixed(2)])
        },
        '$route.params.id': function () {
            this.diskRead = []
            this.diskWrite = []
        }
    }
}
</script>

<style scoped>

</style>
