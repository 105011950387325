<template>
    <div style="width: 600px; height: 300px">
        <apexchart
            v-if="data.length>0"
            width="600"
            height="300"
            :options="options"
            :series="series"
            type="line"
        />
        <b-spinner v-else class="position-absolute m-auto top-0 bottom-0 left-0 right-0" variant="primary" />
    </div>
</template>

<script>
import defaultOptions from '@/core/config/apexcharts/defaultOptions'

export default {
    name: 'CpuGraph',
    props: {
        status: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            data: [],
            options: {
                ...defaultOptions,
                colors: ['#AC0006'],
                labels: ['CPU'],
                yaxis: {
                    max: this.getMaxCpu,
                    min: 0,
                    title: {
                        text: 'CPU'
                    },
                    tickAmount: 4,
                    labels: {
                        formatter: val => `${val}%`
                    }
                },
                xaxis: {
                    tooltip: {
                        enabled: false
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (value) {
                            return value + '%'
                        }
                    }
                }
            }
        }
    },
    computed: {
        series: function () {
            return [
                {
                    name: 'CPU',
                    data: this.data
                }
            ]
        }
    },
    methods: {
        getMaxCpu() {
            return this.status.maxcpu * 100
        }
    },
    watch: {
        status: function (status) {
            this.data.push([Date.now(), status.cpu.toFixed(2) * 100])
        },
        '$route.params.id': function () {
            this.data = []
        }
    }
}
</script>

<style scoped>

</style>
