<template>
    <div>
        <b-row cols-md="2">
            <b-col>
                <b>ID</b>
                <p>{{ server.id }}</p>
            </b-col>
            <b-col>
                <b>Status</b>
                <p>{{ status ? status.status : 'stopped' }}</p>
            </b-col>
            <b-col>
                <b>Titel</b>
                <p><ClickToEdit v-model="server.title" @save="saveTitle" /></p>
            </b-col>
            <b-col>
                <b>Hostname</b>
                <p><ClickToEdit v-model="server.hostname" @save="saveHostname" /></p>
            </b-col>
            <b-col>
                <b>Kerne</b>
                <p>{{ server.cores }}</p>
                <CpuGraph v-if="status" :status="status" />
            </b-col>
            <b-col>
                <b>Arbeitsspeicher</b>
                <p>{{ Math.floor(server.memory / 1024) }} GB</p>
                <MemoryGraph v-if="status" :status="status" />
            </b-col>
            <b-col v-if="status">
                <b>Festplattenauslastung</b>
                <DiskGraph :status="status" />
            </b-col>
            <b-col v-if="status">
                <b>Netzwerkauslastung</b>
                <NetGraph :status="status" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { CloudServer } from '@/core/services/store/models/cloud_server'
import { CloudServerStatus } from '@/core/services/store/models/cloud_server_status'
import ClickToEdit from '@/view/components/helper/ClickToEdit'
import MemoryGraph from '@/view/components/cloudserver/charts/CloudServerMemoryGraph'
import CpuGraph from '@/view/components/cloudserver/charts/CloudServerCpuGraph'
import DiskGraph from '@/view/components/cloudserver/charts/CloudServerDiskGraph'
import NetGraph from '@/view/components/cloudserver/charts/CloudServerNetGraph'

export default {
    name: 'CloudServerOverview',
    components: { NetGraph, DiskGraph, CpuGraph, MemoryGraph, ClickToEdit },
    props: {
        server: {
            type: CloudServer,
            required: true
        },
        status: {
            type: CloudServerStatus,
            required: false,
            default: null
        }
    },
    methods: {
        saveTitle() {
            this.api.updateCloudServer(this.server.id, { title: this.server.title })
            this.server.$save()
        },
        saveHostname() {
            this.api.updateCloudServer(this.server.id, { hostname: this.server.hostname })
            this.server.$save()
        }
    }
}
</script>

<style scoped>

</style>
