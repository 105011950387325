export default {
    noData: 'Es sind noch keine Daten verfügbar',
    chart: {
        toolbar: {
            tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
            }
        }
    }
}
