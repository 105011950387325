<template>
    <div style="width: 600px; height: 300px">
        <apexchart
            v-if="data.length>0"
            width="600"
            height="300"
            :options="options"
            :series="series"
            type="line"
        />
        <b-spinner v-else class="position-absolute m-auto top-0 bottom-0 left-0 right-0" variant="primary" />
    </div>
</template>

<script>
import defaultOptions from '@/core/config/apexcharts/defaultOptions'

export default {
    name: 'MemoryGraph',
    props: {
        status: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            data: [],
            options: {
                ...defaultOptions,
                colors: ['#AC0006'],
                noData: 'Es sind noch keine Daten verfügbar',
                labels: ['RAM'],
                yaxis: {
                    max: this.calcMaxMem,
                    min: 0,
                    title: {
                        text: 'Arbeitsspeicher'
                    },
                    tickAmount: 4,
                    labels: {
                        formatter: val => `${val}mb`
                    }
                },
                xaxis: {
                    tooltip: {
                        enabled: false
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: val => `${val}mb`
                    }
                }
            }
        }
    },
    computed: {
        series: function () {
            return [
                {
                    name: 'RAM',
                    data: this.data
                }
            ]
        }
    },
    methods: {
        calcMaxMem() {
            return Math.round((this.status.maxmem / 1024) / 1024)
        }
    },
    watch: {
        status: function (status) {
            this.data.push([Date.now(), Math.round((status.mem / 1024) / 1024)])
        },
        '$route.params.id': function () {
            this.data = []
        }
    }
}
</script>

<style scoped>

</style>
