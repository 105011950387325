<template>
    <b-select :options="templates" :value="value" @input="$emit('input', $event.target.value)" />
</template>

<script>
import { CloudServerTemplate } from '@/core/services/store/models/cloud_server_template'

export default {
    name: 'CloudServerTemplateSelect',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    computed: {
        templates () {
            return CloudServerTemplate.all().map(el => ({ value: el.key, text: el.title }))
        }
    },
    created() {
        CloudServerTemplate.fetchAll()
    }
}
</script>

<style scoped>

</style>
